<template>
  <div class="col-12 text-center" :class="isDesktop ? 'my-4' : 'mt-3'">
    <form ref="myForm">
      <image-uploader
        :preview=false
        outputFormat="file"
        className="fileInput"
        @input="fileChanged"
      >
        <label class="w-100" slot="upload-label">
      <!-- <span v-if="uploading">uploading</span> -->
      <!-- <span v-else-if="newProfilePic" @click="profilePicUpload" class="clickable">confirm</span> -->
          <figure>
            <img :src="newProfilePicLink" class="profile_pic" :class="{'profile_pic_mobile': !isDesktop}" v-if="newProfilePicLink">
            <img src="@/assets/images/avatar.png" class="profile_pic" :class="{'profile_pic_mobile': !isDesktop}" v-else-if="profile && !profile.img">
            <img :src="serverMedia + 'images/guests/' + profile.img" :class="{'profile_pic_mobile': !isDesktop}" class="profile_pic" v-else-if="profile && profile.img">
            <div class="text-center clickable" :class="isDesktop ? 'camera' : (isMobile ? 'camera-mobile' : 'camera-tablet')">
              <label for="fileInput" class="clickable">
                <span class="material-icons-outlined" :class="isDesktop ? '' : 'material-icons-outlined-mobile'">camera_enhance</span>
              </label>
            </div>
          </figure>
        </label>

      </image-uploader>
    </form>
    <!-- <input type="file" name="prof_pic" id="prof_pic" ref="fileInput" class="inputfile"  @change="fileChanged()" accept="image/*" maxlength=80/> -->
    <two-buttons-popup
      v-if="showConfirmation"
      title="Profile Image"
      text="Do you want to save your change?"
      :btn1="'Yes'"
      :btn2="'No'"
      @close="closeConfirmation"></two-buttons-popup>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'ProfileImage',
  components: {
    'two-buttons-popup': () => import('@/components/modals/TwoButtons.vue')
  },
  data () {
    return {
      newProfilePic: null,
      showConfirmation: false
      // uploading: false
    }
  },
  computed: {
    ...mapGetters([
      'isMobile',
      'isTablet',
      'profile',
      'serverMedia'
    ]),
    isDesktop () {
      return !this.isMobile & !this.isTablet
    },
    newProfilePicLink () {
      if (this.newProfilePic) {
        return URL.createObjectURL(this.newProfilePic)
      }
      return null
    }
  },
  methods: {
    ...mapActions([
      'addProfilePicture'
    ]),
    closeConfirmation (id, value) {
      if (!value) {
        this.$refs.myForm.reset()
        this.newProfilePic = null
        this.showConfirmation = false
      } else {
        this.profilePicUpload()
      }
    },
    profilePicUpload () {
      // this.uploading = true
      const formData = new FormData()
      formData.append('files[0]', this.newProfilePic)
      this.addProfilePicture(formData).then(res => {
        if (res.success) {
          // this.uploading = false
          this.newProfilePic = null
          this.showConfirmation = false
        }
      })
    },
    fileChanged (f) {
      if (f.size > 2000000) {
        alert('File too big. Max file size is 2Mb')
      } else {
        this.showConfirmation = true
        this.newProfilePic = f
      }
    }
  }
}
</script>
<style scoped>
  .profile_pic {
    width: 220px;
    height: 220px;
    object-fit: cover;
    border-radius: 50%;
    border: 1px solid #fff;
    background-color: rgba(209, 211, 212, 1);
  }
  .profile_pic_mobile {
    width: 20vw !important;
    height: 20vw !important;
  }

  .camera {
    width: 55px;
    height: 55px;
    position: absolute;
    bottom: 0%;
    right: 20%;
    background-color: var(--beige);
    border-radius: 50%;
    border: 1px solid #fff;
    padding-top: 5px;
  }

  .camera-mobile {
    width: 30px;
    height: 30px;
    position: absolute;
    bottom: 0%;
    right: -10%;
    background-color: var(--beige);
    border-radius: 50%;
    border: 1px solid #fff;
    /* padding-top: 5px; */
  }
  .material-icons-outlined {
     font-size: 40px;
  }

  .material-icons-outlined-mobile {
     font-size: 20px;
  }

  figure {
    position: relative;
  }
</style>
